body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Quicksand', sans-serif;
}

html, body {
  /* background-color: #eceff4 !important; */
  font-family: 'Quicksand', sans-serif !important;
  color: rgba(0, 0, 0, .7) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: transparent;
}

/* Override bootstrap line for clear button on search input type */
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button !important;
}

.overflow-h {
  overflow: hidden;
}